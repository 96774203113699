@import '../../../../../vars.module.scss';

.turivius-search-operators-ha {
  padding-bottom: 10px;

  p {
    width: 100%;
    word-break: break-word;
    cursor: pointer;
    color: #344054 !important;
    font-size: 12px !important;
    line-height: 35px;

    &:hover {
      background-color: transparent !important;
      color: #344054 !important;
    }
  }

  .operator-btn {
    background-color: $white;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 4px;
    font-weight: 500;
    font-size: 12px !important;
    border: 1px solid #ece4e4;
    cursor: pointer;
  }
}

.operators-popover {
  margin-top: 10px !important;

  mark {
    background-color: #e5f0fe;
  }

  .quote {
    margin-top: 8px;
    padding: 8px;
    background-color: #f9f9f9;
    border-radius: 2px;
    border-left: 4px solid #006af5;
  }
}

.operator-open {
  display: -webkit-box;

  p {
    line-height: 20px;
  }

  .quote {
    margin-top: 8px;
    padding: 8px;
    background-color: #f9f9f9;
    border-radius: 2px;
    border-left: 4px solid #006af5;
    font-size: 12px !important;
  }
}

.operators-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 240px;
  width: 220px;
  z-index: 1000;
}
.operators-card_footer{
  margin-top: -10px;
}

.operator-tooltip-monitoramento {
  position: absolute;
  background-color: #fff;
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000 !important; 
  font-size: 12px;
  color: #344054;

  &.in-results {
    margin-top: 8px;
  }
}
