.synonyms-filter-styles {
  margin-top: 24px;

  .toggle-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .toggle-label {
    font-size: 16px;
    font-weight: 600;
    .synonyms-toggle-label {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #e5e5e5;
    margin: 20px 0;
  }

  .toggle-group {
    label {
      font-size: 14px;
      font-weight: 500;
      display: block;
      margin-bottom: 10px;
    }

    .toggle-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:first-of-type {
        margin-bottom: 0px;
      }
    }

    .toggle-item > h3 {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .box-toggle {
    display: flex;
    // align-items: center;
    gap: 14px;
    height: 24px;
  }

  .box-toggle > span {
    font-size: 12px;
    min-width: 36px;
    padding: 3px 8px;
    text-align: center;
    font-weight: bold;
    background-color: #eff8ff;
    border-radius: 999px;
    color: #053acb;
    font-weight: 600;
  }
}


