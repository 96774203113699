.upgrade-modal {
  background: #f5faff;
  border-radius: 16px;
  padding: 0px 17px 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 54px;

  .rs-modal-content {
    background: #f5faff;
    box-shadow: none;
  }
}

.wrapper-modal {
  margin-top: 50px;
  width: 1066px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.upgrade-modal-content {
  /* flex: 1.4; */
}

/* Ajuste do tamanho da imagem */
.upgrade-svg {
  margin-top: 30px;
  border-radius: 28px !important;
  width: 381px !important;
  height: 591px !important;
  max-width: 381px !important;
  max-height: 591px !important;
  box-shadow: 0px 22px 48px -12px rgba(0, 0, 0, 0.18);
  background-clip: padding-box; /* Impede que a sombra afete a borda */
}

/*------------------------------------------- */

/* Cabeçalho com logo */

/*------------------------------------------- */

/*------------------------------------------- */

.text-block-clean {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  /* padding: 10px; */
  height: 80px;
}

/* Ícones na parte inferior */
/* .card-actions {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;
  margin-top: 4px;
} */

/* Esconder ícones em telas menores */
/* @media (max-width: 1680px) {
  .card-actions {
    display: none;
  }
} */

.btn-cancelar {
  color: #0d91fe;
}

.btn-cancelar:hover {
  text-decoration: underline; /* Adiciona efeito de link ao passar o mouse */
}

.action-icon {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
  width: 180px;
  height: 150px;
  background: white;
  border-radius: 10px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08); */
  border: 1px solid #e4e7ec;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007bff;
}

.selected-action {
  border: 2px solid #007bff;
}
/*------------------------------------------- */

/*------------------------------------------- */

/*------------------------------------------- */

/* Bloco ilustrativo corretamente alinhado */

.upgrade-modal-title {
  font-size: 14px;
  font-weight: 400;
  color: #475467;
}

.custom-button-upgrade {
  width: 217px; /* Largura */
  height: 48px; /* Altura */
  background-color: #053ac8; /* Cor primária (pode ser ajustada) */
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 8px; /* Cantos arredondados */
  cursor: pointer;
  gap: 8px; /* Espaço entre elementos internos */
}

.custom-button-upgrade:hover {
  background-color: #053ac8ff; /* Cor primária (pode ser ajustada) */
  color: #fff;
}

.upgrade-modal-title-bottom {
  font-size: 14px;
  font-weight: 400;
  color: #475467;
  margin-top: 20px;
}

.upgrade-modal-subtitle {
  font-family: Rubik;
  font-size: 32px;
  color: #111;
  margin-top: 10px;
  margin-bottom: 22px;
  font-weight: 400;
  line-height: normal;

  span {
    color: #0d91fe;
    font-size: 32px;
    font-weight: 600;
    line-height: 0;
  }
}

.upgrade-option {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* Essa classe controla (O terceiro e não a segunda bloco de textos do upgrade modal)
pq funciona ? não sei! Mas, blz */
.upgrade-option:nth-last-of-type(2) {
  margin-bottom: 24px;
}

.separator-jurimetria-trabalhista {
  color: '#E4E7EC';
}

.custom-box-icon {
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  padding: 8px 10px;
  margin-top: 4px;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.06),
    /* Primeira sombra - 6% opacidade */ 0px 1px 2px rgba(0, 0, 0, 0.04); /* Segunda sombra - 4% opacidade */
}

.upgrade-option-title {
  font-size: 16px;
  font-weight: 700;
  color: #222;
}

.upgrade-option-description {
  font-size: 16px;
  color: #555;
}

.upgrade-buttons {
  margin-top: 27px;
  display: flex;
  gap: 12px;
}

.upgrade-buttons .rs-btn {
  font-size: 14px;
  padding: 10px 16px;
  border-radius: 8px;
}

.upgrade-modal .upgrade-modal {
  /* background-color: red; */
  /* gap: 100px; */
}
